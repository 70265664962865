console.clear();

import { gsap } from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger);

//VARS
const trailer = document.querySelector(".trailer"),
    sectionStills = document.querySelector(".section--stills"),
    stills = gsap.utils.toArray(".stills");

const headerEl = document.querySelector(".header");
const footerEl = document.querySelector(".footer");
const body = document.querySelector("body");

const keyvisualTop = document.querySelector(".keyvisual__top");
const keyvisualRight = document.querySelector(".keyvisual__right");
const keyvisualBottom = document.querySelector(".keyvisual__bottom");
const keyvisualLeft = document.querySelector(".keyvisual__left");
const keyvisualCenter = document.querySelector(".keyvisual__center");

const partnerLogos = document.querySelector(".partner-logos");
const preloader = document.querySelector(".preloader");
const preloaderTextElements = gsap.utils.toArray(".preloader h2");

const fadeInElements = gsap.utils.toArray(".fadeIn");

function preloaderAnimation() {
    const tl = gsap.timeline();
    tl.fromTo(
        preloaderTextElements,
        { yPercent: 100 },
        { yPercent: 0, duration: 1.2, ease: "power2.inOut" }
    );
    tl.to(preloader, { opacity: 0, delay: 1.5 });
    return tl;
}

function keyVisualBuildUp() {
    const tl = gsap.timeline({
        defaults: { duration: 2, ease: "power2.inOut" },
    });

    tl.fromTo(
        keyvisualTop,
        { yPercent: -100, xPercent: -50 },
        { yPercent: 0, xPercent: -50 },
        "top"
    );
    tl.fromTo(
        keyvisualRight,
        { xPercent: 100, yPercent: -50 },
        { xPercent: 0, yPercent: -50 },
        "top -=2"
    );
    tl.fromTo(
        keyvisualBottom,
        { y: "100%", xPercent: -50 },
        { y: "10%", xPercent: -50 },
        "top"
    );
    tl.fromTo(
        keyvisualLeft,
        { xPercent: -100, yPercent: -50 },
        { xPercent: 0, yPercent: -50 },
        "top -=2"
    );
    tl.fromTo(
        keyvisualCenter,
        { scale: 1.25, opacity: 0, y: "-50%", x: 0 },
        {
            scale: 1,
            opacity: 1,
            y: "-50%",
            x: 0,
            duration: 1.5,
            ease: "back.out(1.25)",
        },
        "top -=1"
    );
    return tl;
}

function keyVisualTrailerSwap() {
    const tl = gsap.timeline({
        defaults: { duration: 1, ease: "power1.inOut" },
    });

    tl.to(keyvisualCenter, { scale: 0.5, opacity: 0 }, "logo");
    tl.to(keyvisualTop, { yPercent: -50 }, "logo");
    tl.to(keyvisualRight, { xPercent: 50 }, "logo");
    tl.to(keyvisualBottom, { yPercent: 50 }, "logo");
    tl.to(keyvisualLeft, { xPercent: -50 }, "logo");
    tl.fromTo(trailer, { opacity: 0 }, { opacity: 1 }, "logo");

    return tl;
}

function headerMoveIn() {
    const tl = gsap.timeline({
        defaults: { duration: 2, ease: "power1.inOut" },
    });
    tl.fromTo(headerEl, { opacity: 0 }, { opacity: 1 });

    return tl;
}
function footerFadeIn() {
    const tl = gsap.timeline({
        defaults: { duration: 2, ease: "power1.inOut" },
    });
    tl.fromTo(footerEl, { opacity: 0 }, { opacity: 1 });

    return tl;
}

// function removeOverflow() {
//     body.style.overflowX = "hidden";
//     body.style.overflowY = "visible";
// }

function partnerMoveIn() {
    const tl = gsap.timeline({
        defaults: { duration: 2, ease: "power1.inOut" },
        // onComplete: () => removeOverflow(),
    });
    tl.fromTo(partnerLogos, { opacity: 0 }, { opacity: 1 });

    return tl;
}

const masterTl = gsap.timeline();
masterTl
    .add(preloaderAnimation())
    .add(keyVisualBuildUp())
    .add(keyVisualTrailerSwap(), "-=0.5")
    .add(headerMoveIn(), "-=1")
    .add(footerFadeIn(), "-=1")
    .add(partnerMoveIn(), "-=1");

fadeInElements.forEach((el) => {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: el,
            markers: false,
            start: "top 75%",
            end: "bottom 80%",
            scrub: 1,
        },
    });
    tl.fromTo(el, { opacity: 0 }, { opacity: 1 });
});

// STILL IMAGE MARQUEE
stills.forEach((stillRow, index) => {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: sectionStills,
            markers: false,
            scrub: 1,
            start: "top 90%",
            end: "bottom -100%",
            pin: false,
        },
    });
    // tl.fromTo(stillImg, { xPercent: 0, x: 0, duration: 2 }, { xPercent: 100 });
    if (stillRow.dataset.direction === "left") {
        tl.fromTo(
            stillRow,
            { xPercent: 0, x: 0, duration: 2 },
            { xPercent: 75 }
        );
    } else if (stillRow.dataset.direction === "right") {
        tl.fromTo(
            stillRow,
            { xPercent: 0, x: 0, duration: 2 },
            { xPercent: -75 }
        );
    }
});
